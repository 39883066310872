import React from 'react'
import {Wrapper} from './components/wrapper'
import './App.css';

export const App = () => {
  return (
    <>
    <Wrapper />
    </>
  )
}
