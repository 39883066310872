import React, { useState, useEffect, useRef } from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { AutoComplete } from "primereact/autocomplete"
import { OverlayPanel } from 'primereact/overlaypanel';
import { Box, Typography, CircularProgress } from '@mui/material';
import CarouselComponent from "./Carousel";
import { uploadImage, getFile, getOrignalImage, getMaskImage, getAnnoImage, putDoc, getMatrix, getDoc, putent, getEntity } from "../services/index"
import { Image } from 'primereact/image';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

export const ClientComponent = ({ gifPath, indPath, top, clientId, reset, handleReset, status, toast }) => {
    const [countries, setCountries] = React.useState([{ name: '1-column format', code: '1-column format', source: 'user' }, { name: '2-column format', code: '2-column format', source: 'user' }, { name: 'data sheets', code: 'data sheets', source: 'user' }, { name: 'forms', code: 'forms', source: 'user' }, { name: 'invoice', code: 'invoice', source: 'user' }]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [entityType, setEntityType] = useState([]);
    const [filteredCountries, setFilteredCountries] = useState(null);
    const [entityTypeOption, setEntityTypeOption] = useState([]);
    const [image, setImage] = useState({ file_download_url: "", file_id: null, name:'', pages:0 });
    const [open, setOpen] = React.useState(false);
    const [originalImage, setOriginalImage] = React.useState({});
    const [maskImage, setMaskImage] = React.useState({});
    const [annotedImage, setannotedImage] = React.useState({});
    const [matrixUrl, setMatrix] = React.useState('');
    const [value, setValue] = useState('');
    const [annotedData, setAnnotedData] = useState(null);
    const op = useRef(null);
    const annotedRef = useRef(null);
    // document_type

    useEffect(() => {
      if (reset) {
        onReset()
      }
    }, [reset])

    useEffect(() => {
      if(annotedData &&annotedRef && annotedRef.current ){
        console.log(annotedData);
        annotedRef.current.innerHTML = annotedData
      }else{
        annotedRef.current.innerHTML = ''
      }
    }, [annotedData])
    

    const onReset = () =>{
        setSelectedCountries([]);
        setOriginalImage({});
        setMaskImage({})
        setannotedImage({})
        handleReset(false)
        setImage({ file_download_url: "", file_id: null,name:'',pages:0 })
    }
    

    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let _filteredCountries;

            if (!event.query.trim().length) {
                _filteredCountries = [...countries];
            }
            else {
                _filteredCountries = countries.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    }

    const onchangeEntityType = async (e) =>{
        if (e.code === 'Enter') {
            let val = Array.isArray(entityType) ? entityType.map(f => f.name).join(',') : ''
            val =  val + ',' + e.target.value
            await putent({entity_type: val}, image.file_id)
            let option = [...entityType.map(f => {return {...f, source:'user'}}), {name: e.target.value, code: e.target.value, source:'user'}]
            setEntityType(option)
            setEntityTypeOption(option)
            e.target.value = ''
        }
    }

    const handleChangeImage = async (e) => {
        if (e.target.files.length) {
            onReset()
            setOpen(true);
            try {
                const image = await uploadImage(e.target.files[0], clientId)
                setImage({
                    file_download_url: image.response.file_download_url,
                    file_id: image.response.file_id,
                    name:image.response.file_name,
                    pages:image.response.num_pages
                });
                let arr = image.response.document_type.split(',')
                let options = countries.map(e => {
                    if (arr.includes(e.code)) {
                        return { ...e, source: 'ai' }
                    } else {
                        return e
                    }
                })
                let entiArr = image.response.entity_type.split(',')
                let options2 = entiArr.map(e => {
                        return { name: e, code : e, source: 'ai' }
                })
                setEntityType(options2)
                setEntityTypeOption(options2)
                setCountries(options)
                setSelectedCountries(options.filter(e => arr.includes(e.code)))
                setOpen(false);
                fetchOrignalStart(image.response.file_id)
                fetchMaskStart(image.response.file_id)
                fetchAnnoStart(image.response.file_id)
            } catch (error) {
                setOpen(false);
            }
        }
    };


    const fetchOrignalStart = async (file_id, page = 1) => {
        let ima = await getOrignalImage(file_id, page)
        const blob = new Blob([ima.data]);
        const url = URL.createObjectURL(blob);
        setOriginalImage({[page - 1] : url }) 
    }

    const fetchOrignal = async (file_id, page = 1) => {
        if (!originalImage[page-1]) {
            let ima = await getOrignalImage(file_id, page)
            const blob = new Blob([ima.data]);
            const url = URL.createObjectURL(blob);
            setOriginalImage({...originalImage, [page - 1] : url }) 
        }
    }

    const fetchMaskStart = async (file_id, page = 1) => {
        let ima = await getMaskImage(file_id, page)
        const blob = new Blob([ima.data]);
        const url = URL.createObjectURL(blob);
        setMaskImage({ [page - 1] : url })
    }

    const fetchMask = async (file_id, page = 1) => {
        if (!maskImage[page-1]) {
            let ima = await getMaskImage(file_id, page)
            const blob = new Blob([ima.data]);
            const url = URL.createObjectURL(blob);
            setMaskImage({...maskImage, [page - 1] : url })
        }
    }

    const fetchAnnoStart = async (file_id, page = 1) => {
        let ima = await getAnnoImage(file_id, page)
        const blob = new Blob([ima.data]);
        const url = URL.createObjectURL(blob);
        setannotedImage({ [page - 1] : url })
    }

    const fetchAnno = async (file_id, page = 1) => {
        if (!annotedImage[page-1]) {
            let ima = await getAnnoImage(file_id, page)
            const blob = new Blob([ima.data]);
            const url = URL.createObjectURL(blob);
            setannotedImage({...annotedImage, [page - 1] : url })
        }
    }

    const itemTemplate = (item) => {
        return (
            <div className="flex align-items-center" style={{ position: 'relative' }}>
                <div className={item.source === 'ai' ? 'aiIconAfter' : ''}>{item.name}</div>
            </div>
        );
    };

    const getmatUrl = async() => {
        let ima = await getMatrix(clientId)
        const blob = new Blob([ima.data]);
        const url = URL.createObjectURL(blob);
        setMatrix(url)
    };

    const updateDocType = async (value) =>{
        try {
            await putDoc({document_type: value.map(e => e.name).join(',')}, image.file_id)
            let docType = await getDoc(image.file_id)
            // let data = await getFile(image.file_id)
            // setImage({
            //     file_download_url: data.response.file_download_url,
            //     file_id: data.response.file_id,
            // });
            let arr = docType.response.document_type.split(',')
            let options = countries.map(e => {
                if (arr.includes(e.code)) {
                    return { ...e, source: 'user' }
                } else {
                    return null
                }
            })

            // setCountries(options)
            setSelectedCountries(options.filter(e => e))
        } catch (error) {
            console.log(error);
        }
    }

    const updateEntity = async (value) =>{
        try {
            await putent({entity_type: value.map(e => e.name).join(',')}, image.file_id)
            let option = [...value.map(f => {return {...f, source:'user'}})]
            setEntityType(option)
            setEntityTypeOption(option)
        } catch (error) {
            
        }
    }
    

    const findEntity = async () =>{
      try {
        if (value === '') {
          toast.current.show({severity:'error', summary: 'Error', detail:'Enter text should not be empty', life: 3000});
          return
        } 
       let data = await getEntity(clientId, value)
        setAnnotedData(data.response.html_text)
      } catch (error) {
        
      }
    }
    return (
      <>
        <Box
          style={{
            height: "200px",
            width: "330px",
            marginRight: "15px",
            marginBottom: "5px",
            border: "2px dashed magenta",
            padding: "30px",
            position: "relative",
          }}
        >
          <img
            src={indPath}
            style={{
              position: "absolute",
              width: "80px",
              top: top ? "-50px" : "-40px",
              left: "6px",
              zIndex: 1,
            }}
            alt="ddd"
          />
          <img
            src={gifPath}
            style={{
              position: "absolute",
              width: "160px",
              height: "83px",
              top: "44px",
              left: "83px",
              zIndex: 2,
            }}
            alt="ddd"
          />
          <img
            className=""
            style={{
              height: "188px",
              width: "230px",
              position: "relative",
              top: "-21px",
            }}
            src="assets/images/dow.png"
            alt="d"
          />
          {status === "ongoing" && (
            <i
              className="pi pi-spin pi-spinner"
              style={{
                fontSize: "14px",
                position: "absolute",
                zIndex: 2,
                top: "11px",
                left: "64%",
              }}
            ></i>
          )}
          <span
            style={{
              fontSize: "14px",
              position: "absolute",
              zIndex: 2,
              top: "11px",
              left: "38%",
              textDecoration: "underline",
              color: "#32b2f1",
              cursor: "pointer",
            }}
            onClick={(e) => {
              op.current.toggle(e);
              getmatUrl();
            }}
          >
            Show Model
          </span>
        </Box>
        <div className="file file--upload" style={{ justifyContent: "center" }}>
          {open ? (
            <>
              <label
                htmlFor={"input-file" + clientId}
                style={{ borderRadius: "40px", height: "45px" }}
              >
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "20px" }}
                ></i>
                &nbsp; Select File
              </label>
            </>
          ) : (
            <>
              <label
                htmlFor={"input-file" + clientId}
                style={{ borderRadius: "40px", height: "45px" }}
              >
                <FileUploadIcon /> Select File
              </label>
            </>
          )}
          <input
            id={"input-file" + clientId}
            type="file"
            onChange={handleChangeImage}
          />
        </div>
        <Typography sx={{ marginBottom: "45px" }}>{image.name}</Typography>
        <Box sx={{ width: "365px" }}>
          {image.file_id && (
            <>
              {" "}
              <Box>
                <Typography sx={{ marginLeft: "16px", float: "left" }}>
                  Document Type:{" "}
                </Typography>
              </Box>
              <AutoComplete
                dropdown
                field="name"
                multiple
                value={selectedCountries}
                selectedItemTemplate={itemTemplate}
                suggestions={filteredCountries}
                completeMethod={search}
                onChange={(e) => {
                  setSelectedCountries(e.value);
                  updateDocType(e.value);
                }}
              />
            </>
          )}
          {image.file_id && (
            <>
              {" "}
              <Box>
                <Typography
                  sx={{ marginLeft: "16px", float: "left", marginTop: "5px" }}
                >
                  Industrial Entity Type:{" "}
                </Typography>
              </Box>
              <AutoComplete
                dropdown
                onKeyPress={onchangeEntityType}
                field="name"
                multiple
                value={entityType}
                selectedItemTemplate={itemTemplate}
                suggestions={entityTypeOption}
                onChange={(e) => {
                  updateEntity(e.value);
                }}
              />
            </>
          )}
          {image.file_id && (
            <>
              {" "}
              <Box>
                <Typography
                  sx={{ marginLeft: "16px", float: "left", marginTop: "5px" }}
                >
                  Enter Text:{" "}
                </Typography>
              </Box>
              <br></br>
                <InputTextarea
                style={{width:'90%', resize: 'vertical'}}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  rows={5}
                />
                <Box sx={{display:'flex', justifyContent:'end', width:'90%'}}>
                <Button onClick={findEntity} className='primary' style={{background:'#32b2f1', padding:'5px', fontSize:'14px', borderColor:'#32b2f1'}} label="Get Entity" /> &nbsp;&nbsp;
                <Button onClick={() => { setAnnotedData(null); setValue('') }} label="Clear" className='secondary' style={{ padding:'5px', fontSize:'14px'}} severity="secondary" outlined />
                </Box>
            </>
          )}
          <div className={annotedData ? 'visible' : 'hidden'} style={{"display":"flex","width":"90%","border":"1px solid lightgrey","borderRadius":"8px","marginTop":"10px","padding":"2px 5px", overflowY:'auto', overflowX:'hidden', display:'block', wordBreak:'break-word'}} ref={annotedRef}>

          </div>
          {originalImage && image.pages > 0 && (
            <>
              <CarouselComponent
                images={new Array(image.pages).fill(1).map((e, i) => {
                  return {
                    itemImageSrc: originalImage[i]
                      ? originalImage[i]
                      : "assets/images/load1.gif",
                    thumbnailImageSrc: originalImage[i],
                    alt: "Description for Image 1",
                    title: "Title 1",
                    index: 0,
                  };
                })}
                getNextPage={(page) => {
                  fetchOrignal(image.file_id, page);
                }}
              />
              <Box>
                <Typography sx={{ paddingRight: "16px" }}>
                  Original Document{" "}
                </Typography>
              </Box>
            </>
          )}
          {maskImage && image.pages > 0 && (
            <>
              <CarouselComponent
                images={new Array(image.pages).fill(1).map((e, i) => {
                  return {
                    itemImageSrc: maskImage[i]
                      ? maskImage[i]
                      : "assets/images/load1.gif",
                    thumbnailImageSrc: maskImage[i],
                    alt: "Description for Image 1",
                    title: "Title 1",
                    index: 0,
                  };
                })}
                getNextPage={(page) => {
                  fetchMask(image.file_id, page);
                }}
              />
              <Box>
                <Typography sx={{ paddingRight: "16px" }}>
                  Masked Document{" "}
                </Typography>
              </Box>
            </>
          )}
          {annotedImage && image.pages > 0 && (
            <>
              {" "}
              <CarouselComponent
                images={new Array(image.pages).fill(1).map((e, i) => {
                  return {
                    itemImageSrc: annotedImage[i]
                      ? annotedImage[i]
                      : "assets/images/load1.gif",
                    thumbnailImageSrc: annotedImage[i],
                    alt: "Description for Image 1",
                    title: "Title 1",
                    index: 0,
                  };
                })}
                getNextPage={(page) => {
                  fetchAnno(image.file_id, page);
                }}
              />
              <Box>
                <Typography sx={{ paddingRight: "16px" }}>
                  Annotated Document{" "}
                </Typography>
              </Box>{" "}
            </>
          )}
          {/* <Button variant="contained" sx={{background:'#32b2f1',marginTop:'50px'}}>Clear</Button> */}
        </Box>
        <OverlayPanel ref={op} showCloseIcon closeOnEscape dismissable={false}>
          <Image src={matrixUrl} alt="Image" width="250" />
        </OverlayPanel>
      </>
    );
}
