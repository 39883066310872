import { client } from "./client";
import { BASE_URL } from "./constants";


export const uploadImage = (file, clientId) => {
  const formData = new FormData();
  formData.append("file", file);

  return new Promise((resolve, reject) => {
    client
      .post(`${BASE_URL}api/doctypeclassify/${clientId}`, formData, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
        }
      })
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const putDoc = (body, clientId) => {
  return new Promise((resolve, reject) => {
    client
      .put(`${BASE_URL}api/doctype/${clientId}`, body, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
        }
      })
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const putent = (body, clientId) => {
  return new Promise((resolve, reject) => {
    client
      .put(`${BASE_URL}api/entitytype/${clientId}`, body, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
        }
      })
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}


export const getDoc = (clientId) => {
  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/doctype/${clientId}?source=user`)
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const getEntity = (clientId, text) => {
  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/entitytype/text/${clientId}?text=${text}` )
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}



export const startRetaining = (value) => {
  if (value === 'Doc Type') {
    return new Promise((resolve, reject) => {
      client
        .post(`${BASE_URL}api/doctype/train`, {type: 1}, {
          'headers': {
            'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          }
        })
        .then(({ data }) => {
          resolve(data)
          // onSuccess(data);
        })
        .catch((error) => {
          reject(error)
        });
    })
    
  } else {
    return new Promise((resolve, reject) => {
      client
        .post(`${BASE_URL}api/entitytype/train`, {type: 1}, {
          'headers': {
            'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          }
        })
        .then(({ data }) => {
          resolve(data)
          // onSuccess(data);
        })
        .catch((error) => {
          reject(error)
        });
    })
  }
}



export const getTrainingStatus = (statusId) => {

  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/doctype/train/status/${statusId}`, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          // 'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const getFile = (fileid) => {

  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/doctype/${fileid}?source=user`, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          // 'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}


export const getOrignalImage = (fileid, page) => {

  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/download/file/${fileid}/page/${page}/image/original`, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          'Content-Type': 'multipart/form-data'
        },
        responseType:'arraybuffer'
      })
      .then((data ) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const getMaskImage = (fileid, page) => {

  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/download/file/${fileid}/page/${page}/image/masked`, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          'Content-Type': 'multipart/form-data'
        },
        responseType:'arraybuffer'
      })
      .then((data ) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const getAnnoImage = (fileid, page) => {

  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/download/file/${fileid}/page/${page}/image/annotated`, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          'Content-Type': 'multipart/form-data'
        },
        responseType:'arraybuffer'
      })
      .then((data ) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}

export const getMatrix = (clientId) => {

  return new Promise((resolve, reject) => {
    client
      .get(`${BASE_URL}api/download/client/${clientId}/image/filter`, {
        'headers': {
          // 'Access-Control-Allow-Origin': 'http://176.9.54.100:9000',
          'Content-Type': 'multipart/form-data'
        },
        responseType:'arraybuffer'
      })
      .then((data ) => {
        resolve(data)
        // onSuccess(data);
      })
      .catch((error) => {
        reject(error)
      });
  })
}