import React,{ useRef, useEffect, useState }  from 'react'
import { SplitButton } from 'primereact/splitbutton';
import Button from '@mui/material/Button';
import { SelectButton } from 'primereact/selectbutton';

export const Header = ({trainigStatus, setTrainingStatus, toast, save, items, getStatus, setClear, status}) => {
    const [intv, setIntv] = useState(0);
    const options = ['Doc Type', 'Entity Type'];
    const [value, setValue] = useState(options[0]);
    useEffect(() => {
        let intervals
        if (trainigStatus) {
             intervals = setInterval(() => {
                getStatus(trainigStatus)
            }, 10000); 
        }
        setIntv(intervals)
    
     return () => clearInterval(intervals);
    }, [trainigStatus]);

    useEffect(() => {
      if (status === 'done') {
        clearInterval(intv)
      }
    }, [status])
    


    
    return (
        <>
            <span style={{ fontSize: '24px', fontWeight: '500', color: 'green', float: 'left' }}>OpenFLaaS: Federated Edge-AI Rooms for Intelligent Document Analysis</span>
            <div style={{ float: 'right', display: 'flex',position:'relative' }}>
                <img style={{ width: '150px', height: '30px', marginTop: '8px' }} src='assets/images/DRIMLogo.png' alt='s' />&nbsp;&nbsp;
                <SplitButton  style={{ float: 'right' }} icon={status === 'ongoing' ? 'pi pi-spin pi-spinner' : status === 'done' ? 'pi pi-check' : 'pi pi-play'} label="Start AI Federation" onClick={() => {
                    if (status === 'ongoing') {
                        toast.current.show({ severity: 'warn', summary: 'Training already started' })
                    } else {
                        save(value)
                    }
                }} model={items} rounded 
                />&nbsp;&nbsp;
                <SelectButton style={{position: 'absolute',top: '50px',left: '210px'}} value={value} onChange={(e) =>{
                    if (e.value) setValue(e.value)
                     }} options={options} />
                {status === 'ongoing' && <><span onClick={() => getStatus(trainigStatus, null)} style={{
                    color: '#32b2f1',
                    position:'absolute',
                    right:'130px',
                    top:'85px',
                    textDecoration:'underline',
                    cursor:'pointer'
                }}>Get Status</span>&nbsp;&nbsp;</>}
                <Button onClick={() => window.location.reload()} variant="contained" sx={{
                    background: '#32b2f1', float: 'right', borderRadius: '20px', height: '42px', ':hover': {
                        borderColor: '#2da0d8',
                        backgroundColor: '#2da0d8',
                    }
                }}>Clear All</Button>

            </div>
        </>
    )
}
