import React, { useRef, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ClientComponent } from './client'
import { Image } from 'primereact/image';
import { startRetaining, getTrainingStatus } from "../services";
import { Toast } from 'primereact/toast';
import { Header } from './Header';

export const Wrapper = () => {
  const canvasRef = useRef(null)
  const [clear, setClear] = React.useState(false);
  const [trainigStatus, setTrainingStatus] = React.useState(null);
  const [interval, setInterval] = React.useState(null);
  const [statusClient, setStatusClient] = React.useState({});
  const [status, setStatus] = React.useState('');
  const toast = useRef(null);
  const items = [
    // {
    //     label: 'Update',
    //     icon: 'pi pi-refresh',
    //     command: () => {

    //     }
    // },

  ];




  useEffect(() => {
    // Create a Canvas:
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.setAttribute('width', window.innerWidth);
    canvas.setAttribute('height', '70');
    // Define a new Path:
    ctx.lineWidth = 3;
    // ctx.lineHeight = 1;
    ctx.strokeStyle = '#32b2f1';
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2 - 10, 0);
    ctx.lineTo(canvas.width / 2 - 10, 25);
    ctx.lineTo(window.innerWidth / 6, 25);
    ctx.lineTo(window.innerWidth / 6, 50);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(canvas.width / 2, 0);
    ctx.lineTo(canvas.width / 2, 50);
    ctx.stroke();


    ctx.beginPath();
    ctx.moveTo(canvas.width / 2 + 10, 0);
    ctx.lineTo(canvas.width / 2 + 10, 25);
    ctx.lineTo(window.innerWidth * 0.83, 25);
    ctx.lineTo(window.innerWidth * 0.83, 50);
    ctx.stroke();
  }, [])





  const getStatus = async (status ) => {
    try {
      if (status) {
        let data = await getTrainingStatus(status)
        let obj = {}
        data.response.clients.forEach(element => {
          obj = {...obj, [element.id]: element.training_status }
        });
        setStatusClient(obj)
        setStatus(data.response.consolidated_status)
        if (data.response.consolidated_status === 'done') {
          toast.current.show({severity:'success', summary: 'Success', detail:'Training completed successfully', life: 3000});
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  
  const save = async (value) => {
    try {
      let data = await startRetaining(value)
      setTrainingStatus(data.response.train_id)
      getStatus(data.response.train_id)
      // start()
    } catch (error) {
      console.log(error);
    }
  };

  const icon = (<i className="pi pi-search-plus"></i>)

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Toast ref={toast} />
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center', padding: '10px', fontSize: '16px' }}>
          <Header trainigStatus={trainigStatus} setTrainingStatus={setTrainingStatus} toast={toast} save={save} items={items} getStatus={getStatus} setClear={setClear} status={status} />

        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Image src="assets/images/img.png" alt="Image" width="250" indicatorIcon={icon} preview />
        </Grid>
        <Grid item sx={{ justifyContent: 'center', display: 'flex', position: 'relative' }} xs={6}>
          <img style={{ width: '230px', height: '230px' }} src='assets/images/cloud.png' alt='s' />
          <img style={{ width: '110px', height: '110px', position: 'absolute', top: '70px', color: 'magenta', borderRadius: '60px' }} alt='s' src='assets/images/cloud.gif' />
          {/* <i className="pi pi-spin pi-spinner" style={{position:'absolute', fontSize: '2.5rem',top: '67px',color: 'magenta' }}></i> */}

          {/* <img style={{width:'150px', height:'150px'}} src='assets/images/cut.png' /> */}
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <canvas ref={canvasRef}></canvas>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ textAlign: '-webkit-center' }}>
          <ClientComponent toast={toast} reset={clear} handleReset={setClear} clientId='0' status={statusClient['0']} gifPath={'assets/images/client1.gif'} top={true} indPath={'assets/images/ind1.png'} />
        </Grid>

        <Grid item xs={4} sx={{ textAlign: '-webkit-center' }}>
          <ClientComponent toast={toast} reset={clear} handleReset={setClear} clientId='1' status={statusClient['1']} gifPath={'assets/images/client2.gif'} top={false} indPath={'assets/images/ind2.png'} />
        </Grid>

        <Grid item xs={4} sx={{ textAlign: '-webkit-center' }}>
          <ClientComponent toast={toast} reset={clear} handleReset={setClear} clientId='2' status={statusClient['2']} gifPath={'assets/images/client3.gif'} top={false} indPath={'assets/images/ind3.png'} />

        </Grid>
        <Grid item xs={1} sx={{ textAlign: 'center' }}>
        </Grid>
      </Grid>
    </div>
  );
}

// export default Wrapper;
