import React, { useState, useRef } from 'react';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';

export default function CarouselComponent({images, getNextPage}) {
    const galleria = useRef(null);
    const galleria2 = useRef(null);
    const iconref = useRef(null);
    const [pro , setProps] = useState('')
    const [activeIndex, setActiveIndex] = useState(0);  
    // const [images, setImages] = useState([{
    //     itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg',
    //     thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg',
    //     alt: 'Description for Image 1',
    //     title: 'Title 1',
    //     index:0
    // },{
    //     itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria2.jpg',
    //     thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria2s.jpg',
    //     alt: 'Description for Image 2',
    //     title: 'Title',
    //     index:1

    // }]);

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc }  alt={item.alt} onClick={() => {setActiveIndex(item.index); galleria.current.show(); }} style={{ width: '85%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    }

    const handleHoverOn = () => {
        if (iconref.current) {
            iconref.current.style.zIndex = 9 
        }
        if (galleria2.current) {
            galleria2.current.getElement().style.filter = 'brightness(0.5)'

        }
    }
    
    const handleHoverOff = () => {
        if (iconref.current) {
            iconref.current.style.zIndex = -1
        }
        if (galleria2.current) {
            galleria2.current.getElement().style.filter = 'brightness(1)'
        }
    }
    

    return (
        <div className="card" id='carrd' style={{ margin: '50px 20px 5px 20px', position:'relative', cursor:'pointer' }} onMouseEnter={handleHoverOn}
        onMouseLeave={handleHoverOff}>
            <Galleria ref={galleria2} value={images} numVisible={5} circular style={{ maxWidth: '640px' }}
                showThumbnails={false} showItemNavigators item={itemTemplate} activeIndex={activeIndex} onItemChange={(e) => {setActiveIndex(e.index); getNextPage(e.index +1)}} thumbnail={thumbnailTemplate} />

            <Galleria ref={galleria} activeIndex={activeIndex} value={images} onItemChange={(e) => { setActiveIndex(e.index); getNextPage(e.index +1)}} numVisible={5} style={{ maxWidth: '50%' }}
                circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
            <div ref={iconref} onClick={() => { galleria.current.show(); }} style={{position:'absolute',zIndex:-1, top:'50%', left:'49%'}}>
                <i className='pi pi-search-plus' style={{ fontSize: '2rem',color:'#fff' }}></i>
            </div>
        </div>
    )
}